import request from '@/utils/request'

// 查询剧评师级别列表
export function listReplyLevel(query) {
  return request({
    url: '/config/reply/level/list',
    method: 'get',
    params: query
  })
}

// 查询剧评师级别详细
export function getReplyLevel(id) {
  return request({
    url: '/config/reply/level/' + id,
    method: 'get'
  })
}

// 新增剧评师级别
export function addReplyLevel(data) {
  return request({
    url: '/config/reply/level',
    method: 'post',
    data: data
  })
}

// 修改剧评师级别
export function updateReplyLevel(data) {
  return request({
    url: '/config/reply/level',
    method: 'put',
    data: data
  })
}

// 删除剧评师级别
export function delReplyLevel(id) {
  return request({
    url: '/config/reply/level/' + id,
    method: 'delete'
  })
}
